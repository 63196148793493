import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.scss'

const Terms = React.lazy(() => import('./pages/Terms'))
const Home = React.lazy(() => import('./pages/Home'))
const Verify = React.lazy(() => import('./pages/Verify'))
const Appeal = React.lazy(() => import('./pages/Appeal'))
const Backgrounds = React.lazy(() => import('./pages/Backgrounds'))
const Dyes = React.lazy(() => import('./pages/Dyes'))
const Sketch = React.lazy(() => import('./pages/Sketch'))
const Login = React.lazy(() => import('./pages/Login'))
const Glossary = React.lazy(() => import('./pages/Glossary'))

export default class App extends React.Component {
  render = () => (
    <div className="app">
      <React.Suspense fallback={null}>
        <Router>
          <Switch>
            <Route exact path="/terms" component={Terms}/>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/verify" component={Verify}/>
            <Route exact path="/appeal" component={Appeal}/>
            <Route exact path="/backgrounds" component={Backgrounds}/>
            <Route exact path="/studio/:hash" component={Sketch}/>
            <Route exact path="/dyes/:uid" component={Dyes}/>
            <Route exact path="/glossary/:system?/:category?/:term?" component={Glossary}/>
            <Route component={Home}/>
          </Switch>
        </Router>
      </React.Suspense>
    </div>
  )
}
