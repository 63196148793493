import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.scss'
import 'primereact/resources/themes/luna-amber/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

if (process.env.NODE_ENV === 'development') {
  eval(`javascript:(function () { var script = document.createElement('script'); script.src="//cdn.jsdelivr.net/npm/eruda"; document.body.appendChild(script); script.onload = function () { eruda.init() } })();`)
  document.title = 'Karuta **DEV SITE**'
}

window.devicePixelRatio = 1

const onResize = () => {
  const bounds = document.body.getBoundingClientRect()
  const ratio = bounds.width / bounds.height
  const targetRatio = 0.75
  const threshold = 40
  const max = 16
  const size = (ratio > targetRatio) ? (bounds.height / threshold) : bounds.width / (threshold * targetRatio)
  document.documentElement.style.fontSize = `${Math.min(max, size)}px`
}

window.addEventListener('resize', onResize)
onResize()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
